<template>
  <div class="content">
    <div class="box_log loginbox">
      <div class="top">
        <img class="logoIcon" src="../../assets/img/index01.svg" alt="" />
        <h2 class="title">八米集运系统</h2>
      </div>
      <div class="main">
        <h3 class="tips_tit">用户登录</h3>

        <!-- 验证码登录 -->
        <el-form
          :model="ruleForm2"
          :rules="rules2"
          ref="ruleForm2"
          label-width="0"
          class="demo-ruleForm"
        >
          <el-form-item label="" prop="userName2" class="rowItem">
            <el-input
              class="loginInput"
              placeholder="请输入手机号码"
              v-model.trim="ruleForm2.userName"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="code" class="rowItem">
            <div class="rowCode">
              <el-input
                class="loginInput"
                placeholder="请输入验证码"
                type="text"
                v-model.trim="ruleForm2.code"
                autocomplete="off"
              ></el-input>
              <button
                :class="codeStatus ? 'getCodeBtn' : 'activeClass'"
                @click="getCode"
                :disabled="codeStatus"
              >
                {{ codeText }}
              </button>
            </div>
          </el-form-item>

          <el-form-item style="text-align: center">
            <el-button class="subBtn" type="primary" @click="oauthAdminByCode()"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import common from "@/assets/js/comm";
import { parse } from "qs";
export default {
  data() {
    var checkUserName2 = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("账号不能为空"));
      }
      callback();
    };
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      }
      callback();
    };
    return {
      ruleForm: {
        password: "",
        userName: "",
        sign: "",
      },
      ruleForm2: {
        code: "",
        userName: "",
        prefix: "",
        sign: "",
      },
      rules2: {
        code: [{ validator: validateCode, trigger: "blur" }],
        userName2: [{ validator: checkUserName2, trigger: "blur" }],
      },
      systemType: 1,
      systemTypeList: [
        {
          value: 0,
          label: "运维系统",
        },
        {
          value: 1,
          label: "管理系统",
        },
      ],
      loginType: "a", //登录方式, a==账号密码登录  b==验证码登录
      codeText: "获取验证码",
      codeStatus: false, //true == 正在获取验证码 false == 没有在倒计时
      timer: "", //计时器
    };
  },
  //页面卸载
  Destroyed() {
    clearInterval(this.timer);
    this.timer = "";
  },
  created() {
    //获取登录方式
    // Api.getLoginMethod().then((res) => {
    //   if (res.data.status == "success") {
    //     let val = res.data.result || "a";
    //     if (val == "primordial_login") {
    //       this.loginType = "a";
    //     } else {
    //       this.loginType = "b";
    //     }
    //   }
    // });
  },
  mounted() {},
  methods: {
    getCode() {
      if (this.ruleForm2.userName.length != 11) {
        this.$message.warning("手机号不正确");
        return;
      }
      let that = this;
      that.codeStatus = true;
      that.codeText = 60;
      clearInterval(that.timer);
      this.numberSplit();
      that.timer = setInterval(() => {
        that.codeText--;
        if (that.codeText <= 0) {
          clearInterval(that.timer);
          that.timer = "";
          that.codeStatus = false;
          that.codeText = "获取验证码";
        }
      }, 1000);
    },
    // 发送短信
    numberSplit() {
      var para = {
        phone: this.ruleForm2.userName,
        prefix: common.prefix || "pc_prefix",
        smsStatus: "phoneLoginCode", //短信类型： 发送手机登陆验证码
      };
      let sign = tools.getSign(para);
      para.sign = sign;
      Api.numberSplit(para).then((res) => {
        if (res.data.status == "success") {
          this.$message.success(res.data.message || "发送成功");
        }
      });
    },

    //验证码登录
    oauthAdminByCode() {
      let that = this;
      let param = {
        code: this.ruleForm2.code, //验证码
        userName: this.ruleForm2.userName,
        prefix: common.prefix || "pc_prefix", //短信前缀
      };
      let sg = tools.getSign(param);
      param.sign = sg;
      Api.oauthAdminByCode(param).then((res) => {
        if (res.data.status == "success") {
          this.$message.success(res.data.message || "登录成功");
          window.sessionStorage.setItem("UserKey", res.data.result.token);
          setTimeout(() => {
            that.$router.push({
              path: "/baseIndex",
            });
          }, 1000);
        }
      });
    },

    submitForm() {
      let that = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.systemType == 1) {
            let signParam = {
              password: that.ruleForm.password,
              userName: that.ruleForm.userName,
            };
            let sign = tools.getSign(signParam); //调用getSign方法获取签名,该方法在tool.js里面
            let param = {
              password: that.ruleForm.password,
              userName: that.ruleForm.userName,
              sign: sign,
            };
            Api.oauthAdmin(param).then((res) => {
              this.$message.success(res.data.message || "登录成功");
              window.sessionStorage.setItem("UserKey", res.data.result.userKey);
              setTimeout(() => {
                that.$router.push({
                  path: "/baseIndex",
                });
              }, 1000);
            });
          } else {
            //运维登录
            let siginobj = [
              {
                key: "password",
                val: that.ruleForm.password,
              },
              {
                key: "username",
                val: that.ruleForm.userName,
              },
            ];
            let sign = tools.getMD5Sign(siginobj);
            let param = {
              password: that.ruleForm.password,
              sign: sign,
              username: that.ruleForm.userName,
            };
            Api.yw_login(param).then((res) => {
              this.$message.success(res.data.message || "登录成功");
              window.sessionStorage.setItem("UserKey", res.data.result.token);
              // setTimeout(() => {
              //   that.$router.push({
              //     path: "/MessagePanel",
              //   });
              // }, 1000);
            });
          }
        } else {
          ////console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped lang='scss'>
$myColor: #ef7c1b;
.content {
  background-color: #f2f2f2;
  height: 100vh;
}
.loginbox {
  .el-input__inner {
    // border-bottom: 1px solid #d7d7d7;
    height: 40px;
    line-height: 40px;
    min-height: 40px;
  }
}
.box_log {
  width: 25%;
  min-width: 370px;
  max-width: 600px;
  height: 460px;
  position: fixed;
  top: 100px;
  right: 10%;
  z-index: 99;
  .top {
    width: 100%;
    height: 100px;
    line-height: 100px;
    // background-color: #f00;
    // position: relative;
    display: flex;
    align-items: center;
    .logoIcon {
      width: 60px;
      height: 60px;
      margin-right: 56px;
    }

    .title {
      // width: 100%;
      flex: 1;
      // text-align: center;
    }
  }

  .main {
    width: 100%;
    height: 360px;
    background-color: #fff;
    box-shadow: 3px 3px 6px #f2f2f2;
    box-sizing: border-box;
    padding: 30px 50px;

    // }

    .subBtn {
      margin: 20px auto;
      background-color: #ef7c1b;

      width: 100%;
    }
    .el-button {
      border: 0;
    }
  }
}
::v-deep {
  .el-input__inner {
    height: 40px;
    line-height: 40px;
    min-height: 40px;
  }
}
.rowItem {
  // border: 1px solid #d7d7d7;
  margin-bottom: 20px;
}
.rowCode {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .loginInput {
    flex: 1;
  }

  .activeClass {
    margin: 0;
    padding: 0 8px;
    margin-left: 10px;
    height: 38px;
    line-height: 38px;
    border: none;
    color: #fff;
    background-color: $myColor;
    border-radius: 4px;
    width: 86px;
  }
  .getCodeBtn {
    margin: 0;
    padding: 0 8px;
    margin-left: 10px;
    height: 38px;
    line-height: 38px;
    border-radius: 4px;
    width: 86px;
    border: 1px solid #d7d7d7;
    color: #333;
  }
}
</style>